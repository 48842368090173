@keyframes bg-scrolling-reverse {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.cf-hero {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  filter: blur(4px);
  z-index: -1;
  opacity: 0.25;
  width: 2000%;
  background-repeat: repeat;
  background-size: contain;

  /* animation: bg-scrolling-reverse 1000s linear infinite; */
}

.cf-hero-claim h1 {
  text-shadow: 0 0 2rem var(--color-white);
}

.cf-crossroad-card {
  padding: 0.25rem;
  background: linear-gradient(
    30deg,
    var(--color-blue-200),
    var(--color-blue-300),
    var(--color-cyan-200),
    var(--color-cyan-300)
  );
  background-size: 400% 400%;
  /* animation: gradient 10s ease infinite; */
}

.cf-crossroad-card.cf-crossroad-card--disabled {
  background: var(--color-grey-125);
}

.cf-crossroad-card__body {
  background: var(--color-white);
}

.cf-crossroad-card--disabled .cf-crossroad-card__body {
  background: var(--color-grey-125);
  color: var(--color-grey-200);
}

.cf-crossroad-card:not(.cf-crossroad-card--disabled):hover
  .cf-crossroad-card__body
  h2 {
  background: linear-gradient(
    30deg,
    var(--color-cyan-200),
    var(--color-blue-300)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cf-mobile-nav {
  position: fixed;
  top: 0;
  right: 0;

  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;

  background: var(--color-white);

  z-index: 1000;
}

.cf-mobile-nav:not(.hidden) {
  display: flex;
}

.cf-app-title {
  background: linear-gradient(
    30deg,
    var(--color-black),
    var(--color-blue-300),
    var(--color-black),
    var(--color-blue-300)
  );
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cf-dashed {
  text-decoration: underline;
  text-decoration-style: dashed;
}
